'use client'

import React, { HtmlHTMLAttributes } from 'react'

type LogoProps = HtmlHTMLAttributes<HTMLOrSVGElement>

export const Logo: React.FC<LogoProps> = (props) => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 1920 1080"
      width="240"
      height="120"
      {...props}
    >
      <rect
        x="61.5"
        y="290.5"
        className="st0 fill-[#4b4b4b] dark:fill-[#2d2d2d]"
        width="1798.5"
        height="499.6"
      />
      <path
        className="st1 fill-[#30F599]"
        d="M336.8,443.9h-99V500h91.5v56.5h-91.5v113.4h-64.4V387.3h163.4V443.9z"
      />
      <path
        className="st1 fill-[#30F599]"
        d="M591,382.2c78.4,0,142.1,63.7,142.1,146.3s-61,146.6-142.1,146.6c-83.6,0-142.5-64.1-142.5-146.6
    S513.2,382.2,591,382.2z M591,612.7c51.4,0,72.6-42.1,72.6-84.3c0-42.1-22.6-84.3-72.6-84.3c-49.7,0-73,42.1-73,84.3
    C518,570.6,538.3,612.7,591,612.7z"
      />
      <path
        className="st1 fill-[#30F599]"
        d="M956.2,387.3c52.4,0,92.1,28.4,92.1,83.9c0,42.5-23.3,70.9-57.9,81.9l97.3,116.8h-81.9l-86.3-111.7v111.7
    h-64.4V387.3H956.2z M927.1,512.7c24.3,0,53.4-1.7,53.4-37s-29.1-37-53.4-37h-7.5v74H927.1z"
      />
      <path
        className="st1 fill-[#30F599]"
        d="M1449.5,573c-15.4,71.6-75,102.1-130.5,102.1c-76,0-137.7-65.4-137.7-146.3c0-81.2,58.2-146.6,137.7-146.6
    c49.7,0,84.3,18.5,108.9,48.3l-42.1,46.2c-16.4-26.4-38-36.3-63-36.3c-39.7,0-71.9,39.4-71.9,88.4c0,48,32.2,87,71.9,87
    c30.8,0,61.3-19.9,61.3-54.5H1319v-49.7h130.5V573z"
      />
      <path
        className="st1 fill-[#30F599]"
        d="M1733.1,443.9h-97.3V500h93.2v56.5h-93.2v56.9h97.3v56.5h-161.7V387.3h161.7V443.9z"
      />
    </svg>
  )
}
