'use client'

import {
  Button,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Flex,
  FormControl,
  FormLabel,
  Text,
  useBoolean,
} from '@chakra-ui/react'
import { forwardRef, useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'

export type InputProps = ChakraInputProps & {
  name: string
  id: string
  type?: React.HTMLInputTypeAttribute
  icon?: React.ReactElement
  error?: FieldError
  label?: string
  defaultValue?: string
  appea?: 'secondary'
}

const InputBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (
  { name, id, icon, type, error, label, appea, defaultValue, ...rest },
  ref,
) => {
  const [show, setShow] = useBoolean(false)
  const [expandLabel, setExpandLabel] = useBoolean(false)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const input = document.getElementById(id) as HTMLInputElement

    if (input) {
      setInputValue(defaultValue || input.value)
    }
  }, [defaultValue, id])

  const handleClick = () => setShow.toggle()
  return (
    <>
      <FormControl
        display="flex"
        flexDirection="row"
        onBlur={() => {
          setExpandLabel.off()
        }}
        {...(appea !== 'secondary'
          ? {
              pr: '0.5',
              mt: '1',
              pb: '0.5',
            }
          : {
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderRadius: '0 !important',
              mt: '8',

              ...(error
                ? {
                    // borderColor: 'red.200',
                  }
                : {
                    borderColor: 'gray.300',
                    _hover: {
                      '.formLabel': {
                        color: 'blue.200',
                      },
                      borderColor: 'blue.200',
                    },
                  }),
            })}
        bg={error ? 'red.300' : rest.bg || 'blackAlpha.500'}
        borderRadius="md"
        w="100%"
      >
        {label && (
          <FormLabel
            className="formLabel"
            mt="2"
            pointerEvents="none"
            zIndex="1"
            pos="absolute"
            {...(error
              ? {
                  color: 'red.300',
                }
              : {
                  color: 'gray.300',
                })}
            transition="ease-in-out 200ms"
            transitionProperty="transform, font-size"
            {...(expandLabel || inputValue
              ? {
                  fontSize: 'xs',
                  transform: 'translate(20px, -25px)',
                }
              : {
                  fontSize: 'md',
                  transform: 'translate(20px, 5px)',
                })}
          >
            {label}
          </FormLabel>
        )}
        {icon && (
          <Flex
            className="bg-white dark:bg-neutral-800"
            borderLeftRadius="md"
            alignItems="center"
            color={error ? 'red.300' : 'blackAlpha.500'}
            pl="4"
          >
            {icon}
          </Flex>
        )}
        <ChakraInput
          id={id}
          name={name}
          title={name}
          onBlur={(event) => {
            if (!event.target.value) {
              setExpandLabel.off()
            }
          }}
          onInput={(event) => {
            setInputValue((event.target as HTMLInputElement).value)
          }}
          onFocus={() => {
            setExpandLabel.on()
          }}
          onChange={(event) => {
            setInputValue(event.target.value)

            if (event.target.value) {
              setExpandLabel.on()
            } else {
              setExpandLabel.off()
            }
          }}
          ref={ref}
          type={show ? 'text' : type}
          bg="white"
          className="input bg-white dark:bg-neutral-800"
          {...(appea === 'secondary'
            ? {
                borderRadius: 0,
                p: '6',
                border: 0,
                pb: '10',
              }
            : {
                borderRadius: '5px',
              })}
          {...(icon
            ? {
                borderLeftRadius: 0,
              }
            : {})}
          borderColor="transparent"
          size="lg"
          _hover={{
            borderColor: rest.borderColor || 'transparent',
          }}
          _focusVisible={{
            borderColor: rest.borderColor || 'transparent',
            outline: 'none',
          }}
          display="block"
          py="7"
          outline="0"
          fontSize={['sm', 'md']}
          textColor="gray.300"
          color="gray.300"
          {...rest}
        />
        {type === 'password' && (
          <Button
            width="3.2rem"
            position="absolute"
            top="12px"
            right="10px"
            h="2.05rem"
            size="sm"
            onClick={handleClick}
          >
            {show ? <MdVisibilityOff size={17} /> : <MdVisibility size={17} />}
          </Button>
        )}
      </FormControl>
      {!!error && (
        <Text
          color="red.300"
          fontSize="xs"
          fontWeight="bold"
          mt="1"
          textAlign="left"
        >
          {error.message}
        </Text>
      )}
    </>
  )
}

export const Input = forwardRef(InputBase)
