'use client'

import { Logo } from '@/components/logo'
import React, { useState } from 'react'
import { PassRecovery } from './components/pass-recovery'
import { Login } from './components/login'

type LoginOrRecoveryProps = {
  _?: unknown
}

export const LoginOrRecovery: React.FC<LoginOrRecoveryProps> = () => {
  const [passwordRecoveryMode, setPasswordRecoveryMode] = useState(false)

  return (
    <>
      <Logo className="w-xs mx-auto" />
      {passwordRecoveryMode ? (
        <PassRecovery backToLogin={() => setPasswordRecoveryMode(false)} />
      ) : (
        <Login goToRecovery={() => setPasswordRecoveryMode(true)} />
      )}
    </>
  )
}
