'use client'

import { langKey } from '@/lib/keys'
import Image from 'next/image'

import { API_VERSION } from '@/config'
import { useAuth } from '@/hooks/use-auth'
import { getLocale } from '@/lib/locale'
import { getApi } from '@/services/api'
import { useTranslations } from 'next-intl'
import { usePathname, useRouter } from 'next-intl/client'
import { useParams } from 'next/navigation'
import { setCookie } from 'nookies'
import { useMemo } from 'react'
import BrazilImg from '../../../public/assets/imgs/brazil.png'
import SpainImg from '../../../public/assets/imgs/spain.png'
import UsaImg from '../../../public/assets/imgs/usa.png'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../ui/select'

type SelectLangProps = {
  defaultLang?: string
  userId?: string
  update?: boolean
  onSelect?: (lang: string) => void
}

export const SelectLang: React.FC<SelectLangProps> = ({
  defaultLang,
  userId,
  update = true,
  onSelect,
}) => {
  const router = useRouter()
  const pathname = usePathname()
  const locale = useParams().lang.toString()
  const { isAuthenticated, user } = useAuth()
  const t = useTranslations()
  const sessionUserId = user && String(user.id)

  const changeLanguage = async (lang: string) => {
    if (!update) return

    if (!userId || (userId && userId === sessionUserId)) {
      setCookie(undefined, langKey, lang)

      if (onSelect) onSelect(lang)

      if (isAuthenticated) {
        ;(await getApi()).patch(`${API_VERSION}/user/lang`, {
          lang: getLocale(lang, true),
        })
      }
      router.push(pathname, {
        locale: getLocale(lang),
      })
    } else if (userId) {
      ;(await getApi()).patch(`${API_VERSION}/user/lang`, {
        lang: getLocale(lang, true),
        userId: Number(userId),
      })
    }
  }

  const parsedLocale = useMemo(() => getLocale(locale, true), [locale])

  return (
    <Select
      defaultValue={defaultLang || parsedLocale}
      onValueChange={($locale) => {
        if (onSelect && update === false) {
          onSelect($locale)
        } else {
          changeLanguage($locale)
        }
      }}
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue
          placeholder={t('screens.Settings.Users.columns.lang.title')}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>
            {t('screens.Settings.Users.columns.lang.title')}
          </SelectLabel>
          <SelectItem value="pt-BR">
            <div className="flex items-center gap-2 dark:text-white">
              <Image
                width="32"
                height="32"
                className="mr-3 h-8 w-8 rounded-full"
                src={BrazilImg.src}
                alt="Português"
              />
              Português
            </div>
          </SelectItem>
          <SelectItem value="en-US">
            <div className="flex items-center gap-2 dark:text-white">
              <Image
                width="32"
                height="32"
                className="mr-3 h-8 w-8 rounded-full"
                src={UsaImg.src}
                alt="English"
              />
              English
            </div>
          </SelectItem>
          <SelectItem value="es">
            <div className="flex items-center gap-2 dark:text-white">
              <Image
                width="32"
                height="32"
                className="mr-3 h-8 w-8 rounded-full"
                src={SpainImg.src}
                alt="Español"
              />
              <span>Español</span>
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
