import { Input } from '@/components/input'
import { SelectLang } from '@/components/select-lang'
import { Button } from '@/components/ui/button'
import { ToastAction } from '@/components/ui/toast'
import { useToast } from '@/components/ui/use-toast'
import { useAuth } from '@/hooks/use-auth'
import { zodResolver } from '@hookform/resolvers/zod'
import { Loader2 } from 'lucide-react'
import { useLocale, useTranslations } from 'next-intl'
import { useRouter, useSearchParams } from 'next/navigation'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaLock } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { z } from 'zod'

type LoginProps = {
  goToRecovery: () => void
}

const signInFormSchema = (t: (key: string) => string) =>
  z.object({
    email: z
      .string()
      .min(1, { message: t('globals.errors.email.required') })
      .email({ message: t('globals.errors.email.invalid') }),
    password: z
      .string()
      .min(1, { message: t('globals.errors.password.required') }),
  })

type SignInFormSchema = z.infer<ReturnType<typeof signInFormSchema>>

export const Login: React.FC<LoginProps> = ({ goToRecovery }) => {
  const t = useTranslations()
  const { register, handleSubmit, formState } = useForm<SignInFormSchema>({
    resolver: zodResolver(signInFormSchema(t)),
  })
  const router = useRouter()
  const { signIn } = useAuth()
  const locale = useLocale()
  const searchParams = useSearchParams()
  const callbackUrl = searchParams.get('callbackUrl')
  const { toast } = useToast()
  const handleSignIn: SubmitHandler<SignInFormSchema> = async ({
    email,
    password,
  }) => {
    const success = await signIn({
      provider: 'credentials',
      email,
      password,
      callbackUrl,
    })

    if (!success) {
      toast({
        variant: 'destructive',
        title: 'Não foi possível autenticar.',
        description: 'Ocorreu um problema ao tentar realizar a autenticação.',
        action: (
          <ToastAction altText="Tente novamente">Tente novamente</ToastAction>
        ),
      })
    } else {
      router.push(callbackUrl || `/${locale}/app`)
    }
  }

  return (
    <>
      <form
        className="flex flex-col gap-1 "
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Input
          id="auth__email"
          placeholder={t('globals.email')}
          icon={<MdEmail />}
          error={formState.errors.email}
          {...register('email')}
        />
        <Input
          id="auth__password"
          icon={<FaLock />}
          type="password"
          autoComplete="on"
          error={formState.errors.password}
          placeholder={t('globals.password')}
          {...register('password')}
        />
        <Button variant="ternary" type="submit" size="xl" className="mt-5">
          {formState.isSubmitting ? (
            <Loader2 className="w-4 animate-spin" />
          ) : (
            t('globals.enter')
          )}
        </Button>
      </form>
      <div className="mt-4 flex flex-wrap items-center justify-between gap-2 px-2">
        <Button
          type="button"
          className="text-uset bg-transparent p-2 shadow-none hover:bg-transparent dark:bg-transparent dark:hover:bg-transparent"
          onClick={goToRecovery}
        >
          <p className="underline">{t('screens.Auth.forgotPasswordMessage')}</p>
        </Button>
        <SelectLang />
      </div>
    </>
  )
}
