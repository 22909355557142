import { parseCookies } from 'nookies'
import { langKey } from './keys'
import { enUS, es, ptBR } from 'date-fns/locale'

export const locales = ['en', 'pt', 'es']
export const dateLocales = { en: enUS, pt: ptBR, es }

export const getLocale = (lang?: string, force?: boolean) => {
  const cookies = parseCookies()
  const locale = lang || cookies[langKey]

  if (lang && !force) {
    if (locale === 'pt-BR') {
      return 'pt'
    }

    if (locale === 'en-US') {
      return 'en'
    }
  } else {
    if (locale === 'pt') {
      return 'pt-BR'
    }

    if (locale === 'en') {
      return 'en-US'
    }
  }

  return locale
}
