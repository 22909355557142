'use client'

import { Input } from '@/components/input'
import { Button } from '@/components/ui/button'
import { ToastAction } from '@/components/ui/toast'
import { useToast } from '@/components/ui/use-toast'
import { forgotPass } from '@/services'
import { ChakraProps } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Loader2 } from 'lucide-react'
import { useTranslations } from 'next-intl'
import { SubmitHandler, useForm } from 'react-hook-form'
import { SiMaildotru } from 'react-icons/si'
import { useMutation } from 'react-query'
import { z } from 'zod'

type PassRecoveryProps = ChakraProps & {
  backToLogin: () => void
}

const passRecoveryFormSchema = (t: (key: string) => string) =>
  z.object({
    email: z
      .string({
        required_error: t('globals.errors.email.required'),
      })
      .email(t('globals.errors.email.invalid')),
  })

type PassRecoveryFormData = z.infer<ReturnType<typeof passRecoveryFormSchema>>

export const PassRecovery: React.FC<PassRecoveryProps> = ({ backToLogin }) => {
  const t = useTranslations()
  const { toast } = useToast()

  const { formState, handleSubmit, register } = useForm<PassRecoveryFormData>({
    resolver: zodResolver(passRecoveryFormSchema(t)),
  })

  const forgotPassMutation = useMutation<void, void, PassRecoveryFormData>(
    'forgotPass',
    async (data) => {
      await forgotPass(data)
    },
    {
      onSuccess: () => {
        toast({
          title: t('screens.Auth.RecoverPass.messages.success'),
        })
      },
      onError: () => {
        toast({
          title: t('screens.Auth.RecoverPass.messages.error'),
          variant: 'destructive',
          action: (
            <ToastAction altText="Tente novamente">Tente novamente</ToastAction>
          ),
        })
      },
    },
  )

  const handlePassRecovery: SubmitHandler<PassRecoveryFormData> = async (
    data,
  ) => {
    forgotPassMutation.mutate(data)
  }

  return (
    <>
      <h1 className="text-2xl font-bold">
        {t('screens.Auth.RecoverPass.title')}
      </h1>
      <form onSubmit={handleSubmit(handlePassRecovery)} className="mt-4">
        <Input
          icon={<SiMaildotru />}
          id="forgotPass__email"
          placeholder={t('globals.email')}
          error={formState.errors.email}
          {...register('email')}
        />
        <div className="mt-3 flex gap-2">
          <Button variant="ternary" size="xl" type="submit" className="w-full">
            {forgotPassMutation.isLoading ? (
              <Loader2 className="w-4 animate-spin" />
            ) : (
              t('screens.Auth.RecoverPass.title')
            )}
          </Button>
          <Button
            onClick={backToLogin}
            variant="ternary"
            size="xl"
            className="w-full"
          >
            {t('globals.back')}
          </Button>
        </div>
      </form>
    </>
  )
}
